@media screen and (min-width: 567px) {
  #page-order .main-column .page-content-wrapper .breadcrumb-container {
    padding-left: var(--padding-sm);
  }
}
#page-order .main-column .page-content-wrapper .container.my-4 {
  background-color: var(--fg-color);
}
@media screen and (min-width: 567px) {
  #page-order .main-column .page-content-wrapper .container.my-4 {
    padding: 1.25rem 1.5rem;
    border-radius: var(--border-radius-md);
    box-shadow: var(--card-shadow);
  }
}

@media screen and (max-width: 567px) {
  .indicator-container {
    padding-bottom: 0.8rem;
  }
}

.order-items {
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--border-color);
  color: var(--gray-700);
}
@media screen and (max-width: 567px) {
  .order-items {
    align-items: flex-start !important;
  }
}
@media screen and (max-width: 567px) {
  .order-items .col-2 {
    flex: auto;
    max-width: 28%;
  }
}
.order-items .order-item-name {
  font-size: var(--text-base);
  font-weight: 500;
}
.order-items .btn:focus,
.order-items .btn:hover {
  background-color: var(--control-bg);
}
@media screen and (max-width: 567px) {
  .order-items .col-6 {
    max-width: 100%;
  }
}
.order-items .col-6.order-item-name {
  font-size: var(--text-base);
}

.item-grand-total {
  font-size: var(--text-base);
}

.list-item-name,
.item-total,
.order-container,
.order-qty {
  font-size: var(--text-md);
}

@media screen and (max-width: 567px) {
  .d-s-n {
    display: none;
  }
}

@media screen and (min-width: 567px) {
  .d-l-n {
    display: none;
  }
}

.border-btm {
  border-bottom: 1px solid var(--border-color);
}

.order-taxes {
  display: flex;
}
@media screen and (min-width: 567px) {
  .order-taxes {
    justify-content: flex-end;
  }
}
.order-taxes .col-4 {
  padding-right: 0;
}
.order-taxes .col-4 .col-8 {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 567px) {
  .order-taxes .col-4 {
    padding-left: 0;
    flex: auto;
    max-width: 100%;
  }
}

.filter-options {
  max-height: 300px;
  overflow: auto;
}

table .no-image-item {
  margin: auto;
  height: 84px !important;
  width: 84px !important;
}

#search-box {
  background-color: white;
  height: auto !important;
  padding-left: 2.5rem;
  border: 1px solid var(--gray-200);
}

.item-cart .product-code {
  padding-top: 0 !important;
}
.item-cart .btn-explore-variants {
  min-width: 135px;
  max-height: 30px;
  float: right;
  padding: 0.25rem 1rem;
}
.item-cart .input-group-prepend .cart-btn {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
.item-cart .input-group-append .cart-btn {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.item-cart .remove-cart-item {
  border-radius: 6px;
  border: 1px solid var(--gray-100);
  width: 28px;
  height: 28px;
  font-weight: 300;
  color: var(--gray-700);
  background-color: var(--gray-100);
  float: right;
  cursor: pointer;
  margin-top: 0.25rem;
  justify-content: center;
}
.item-cart .remove-cart-item-logo {
  margin-top: 2px;
  margin-left: 2.2px;
  fill: var(--gray-700) !important;
}
.item-cart .number-spinner {
  width: 75%;
  min-width: 105px;
}
.item-cart .number-spinner .cart-btn {
  background: var(--gray-100);
  box-shadow: none;
  width: 24px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  color: var(--gray-700);
}
.item-cart .number-spinner .cart-qty {
  height: 28px;
  font-size: 13px;
}
.item-cart .number-spinner .cart-qty:disabled {
  background: var(--gray-100);
  opacity: 0.65;
}

#products-grid-area .product-code {
  padding-top: 0 !important;
}
#products-grid-area .btn-explore-variants {
  min-width: 135px;
  max-height: 30px;
  float: right;
  width: 100%;
  padding: 0.25rem 1rem;
}
#products-grid-area .input-group-prepend .cart-btn {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
#products-grid-area .input-group-append .cart-btn {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
#products-grid-area .remove-cart-item {
  border-radius: 6px;
  border: 1px solid var(--gray-100);
  width: 28px;
  height: 28px;
  font-weight: 300;
  color: var(--gray-700);
  background-color: var(--gray-100);
  float: right;
  cursor: pointer;
  margin-top: 0px;
  justify-content: center;
}
#products-grid-area .remove-cart-item-logo {
  margin-top: 2px;
  margin-left: 2.2px;
  fill: var(--gray-700) !important;
}
#products-grid-area .number-spinner {
  width: 75%;
  min-width: 105px;
}
#products-grid-area .number-spinner .cart-btn {
  background: var(--gray-100);
  box-shadow: none;
  width: 24px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  color: var(--gray-700);
}
#products-grid-area .number-spinner .cart-qty {
  height: 28px;
  font-size: 13px;
}
#products-grid-area .number-spinner .cart-qty:disabled {
  background: var(--gray-100);
  opacity: 0.65;
}

#products-list-area {
  padding: 0 5px;
}

.cart-qty {
  width: 70px;
}

@media (min-width: 320px) and (max-width: 990px) {
  #custom-search-box {
    width: 360px;
  }
  .cart-action-container span {
    display: none;
  }
  .input-group-prepend, .input-group-append, .input-group-append span, .remove-cart-item span {
    display: flex !important;
  }
  #products-list-area .number-spinner {
    margin-left: -20px !important;
  }
  #products-grid-area image-view .number-spinner {
    margin-left: 0 !important;
  }
  #products-grid-area .btn-explore-variants {
    margin-right: 0 !important;
  }
}
.input-group {
  width: 100% !important;
}

.shopping-cart-header {
  font-weight: bold;
}

.list-row {
  background-color: white;
  padding-bottom: 1rem;
}

[data-doctype="Item Group"],
[data-doctype=Item] {
  padding: 10px;
}

#custom-search-box {
  background-color: white;
  height: auto;
  padding-left: 2.5rem;
}

.custom-dropdown-menu {
  padding: 0.25rem;
  box-shadow: var(--shadow-lg);
  border-color: var(--gray-200);
}

.address-card {
  cursor: pointer;
  position: relative;
}
.address-card .check {
  display: none;
}
.address-card.active {
  border-color: var(--primary);
}
.address-card.active .check {
  display: inline-flex;
}

.check {
  display: inline-flex;
  padding: 0.25rem;
  background: var(--primary);
  color: white;
  border-radius: 50%;
  font-size: 12px;
  width: 24px;
  height: 24px;
}

.website-list {
  background-color: var(--fg-color);
  padding: 0 var(--padding-lg);
  border-radius: var(--border-radius-md);
}
@media screen and (max-width: 567px) {
  .website-list {
    margin-left: -2rem;
  }
}
.website-list.result {
  border-bottom: 1px solid var(--border-color);
}

.transaction-list-item {
  padding: 1rem 0;
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
.transaction-list-item:only-child, .transaction-list-item:last-child {
  border: 0;
}
.transaction-list-item a.transaction-item-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  opacity: 0;
  overflow: hidden;
  text-indent: -9999px;
  z-index: 0;
}

.place-order-container {
  text-align: right;
}

.kb-card .card-body > .card-title {
  line-height: 1.3;
}

.list-item-name,
.item-total {
  font-size: var(--font-size-sm);
}

@media screen and (max-width: 567px) {
  .items-preview {
    margin-top: 1rem;
  }
}